@import "~styles/variables";
.sidebar {
  margin-top: 76px;
  padding: 2rem;
  background-color: #f6f8fa;
  height: calc(100vh - 76px);
  overflow-y: auto;
  @media screen and (max-width: 1280px) {
    padding: 1rem 1.5rem;
  }
  @media screen and (max-width: 768px) {
    margin-top: 0;
    height: auto;
  }
  .title {
    font-size: 24px;
    color: $borderColor;
  }

  .search-box {
    margin-bottom: 3rem;
    margin-top: 1rem;
    @media screen and (max-width: 1280px) {
      margin-bottom: 1rem;
    }
  }

  .filter-group {
    margin-bottom: 1.5rem;
    @media screen and (max-width: 1280px) {
      margin-bottom: 0.8rem;
    }
    .filter-group-title {
      font-size: 18px;
      color: $borderColor;
      margin-bottom: 24px;
      @media screen and (max-width: 1280px) {
        margin-bottom: 0.6em;
      }
    }
    .halfs {
      display: flex;
      flex-flow: row wrap;
      @media (min-width: 769px) and (max-width: 1123px) {
        display: block;
      }
      .half {
        width: 50%;
        @media (min-width: 769px) and (max-width: 1123px) {
          width: 100%;
        }
        margin-bottom: 24px;
        &:nth-child(3),
        &:nth-child(4) {
          margin-bottom: 0;
        }
      }
    }
  }

  .filter-actions {
    display: flex;
    flex-flow: row wrap;
    flex-grow: 1;
    margin-top: 1.5rem;
    @media screen and (max-width: 1280px) {
      margin-bottom: 0.8rem;
    }
  }
}
