@import "./variables";
// Bootstrap
@import "~bootstrap/scss/bootstrap.scss";
@import "~@fortawesome/fontawesome-free/css/all.min.css";

// Toastify
@import "~react-toastify/scss/main";

body:not(.se-draft-editor) {
  font-family: $font-family-base;
  color: #1f2327;
  font-size: 14px;
}

.navbar-brand {
  img {
    width: 250px;
    @media screen and (max-width: 342px) {
      width: 180px;
    }
  }
}

.header-desktop {
  padding: 0;
  @media screen and (max-width: 769px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

input {
  &.form-control:not(.home-search) {
    border: 1px solid $borderColor;
    color: $borderColor;
    border-radius: 2px;
    font-size: 14px;
    box-sizing: border-box;
    transition: all 0.5s ease-in-out;
    height: 36px;
    &:focus {
      box-shadow: none;
      border: 2px solid $borderColor;
    }
  }
}

.btn {
  border-radius: 0;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 14px;
  &:focus {
    box-shadow: none;
  }
}

.text-bold {
  font-weight: bold !important;
}
.sidebar-container {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  @media all and (max-width: 768px) {
    position: relative;
    z-index: 1;
  }
}
.fixed-top {
  z-index: 4;
  @media all and (max-width: 768px) {
    z-index: 2;
  }
}
.grecaptcha-badge {
  display: none !important;
}

.underline {
  text-decoration: underline;
}
.navbar-nav {
  li {
    a {
      @media screen and (max-width: 500px) {
        text-align: center;
      }
    }
  }
}

body.no-internet {
  .internet-connectivity {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
  }
}
