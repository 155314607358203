@import "~styles/variables";
.date-picker {
  .react-datepicker__month-select,
  .react-datepicker__year-select {
    border: 1px solid $borderColor;
    color: $borderColor;
    border-radius: 2px;
    font-size: 14px;
    box-sizing: border-box;
    transition: all 0.5s ease-in-out;
    height: 30px;
  }
}
