@import "~styles/variables";

.item-404 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &-page {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div {
    color: $site-gray;
    font-style: italic;
    font-size: 1.3rem;
    font-weight: normal;
  }
}
