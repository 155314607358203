@import "styles/variables";

.se-rich-text {
  margin-top: 1.2rem;
  > div:nth-child(2) {
    padding: 16px;
    min-height: 120px;
    box-sizing: border-box;
    border: 1px solid #cfcfcf;
    cursor: text;
    .public-DraftEditor-content {
      min-height: 120px;
    }
    .public-DraftEditorPlaceholder-root {
      color: #747474;
    }
  }
  .menuButtons {
    border: none !important;

    .buttonMenu {
      position: relative;
      display: flex;
      position: relative;
      justify-content: space-around;
      align-self: space-around;
    }

    button {
      border-radius: 2px;
      margin: 0px 4px 4px;
      width: 32px;
      height: 32px;
      font-family: "Times";
      font-size: 16px;
      text-align: center;
      align-items: center;
      border: none;
      color: #263135;
      cursor: pointer;
      border: none;
      border-radius: 4px;
      transition: all 0.3s ease 0s;
      &:hover {
        background-color: $primary;
        color: #fff;
      }
      &.underline {
        text-decoration: underline;
      }
    }
  }
}
