@import "~styles/variables";

.se-sort {
  max-width: 350px;
  h4 {
    font-size: 18px;
    color: $borderColor;
    margin-bottom: 24px;
  }
  .halfs {
    display: flex;
    flex-flow: row wrap;
    @media (min-width: 769px) and (max-width: 1123px) {
      display: block;
    }
    .half {
      width: 50%;
      @media (min-width: 769px) and (max-width: 1123px) {
        width: 100%;
      }
      margin-bottom: 24px;
      &:nth-child(3),
      &:nth-child(4) {
        margin-bottom: 0;
      }
    }
  }
}
