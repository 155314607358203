@import "~styles/variables";
.leave-programs-modal {
  padding: 60px 35px;
  background-color: white;
  .modal-content {
    border: none;
  }
  h4 {
    font-size: 24px;
    line-height: 29px;
  }

  .buttons {
    margin-top: 35px;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
}
