@import "~styles/variables";
.map-details {
  padding: 60px 35px;
  background-color: white;
  .modal-content {
    border: none;
    h4 {
      span {
        width: 24px;
        height: 24px;
      }
    }
  }
}
