@import "~styles/variables";
$feedbackBorder: #cfcfcf;
.feedback-inner {
  input.form-control {
    border: 1px solid $feedbackBorder;
    &:focus {
      border: 1px solid $feedbackBorder;
    }
  }
  .srv-validation-message {
    color: red;
    font-size: 12px;
  }
}
