@font-face {
  font-family: "Didact Gothic";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Didact Gothic Regular"), local("DidactGothic-Regular"),
    url(https://fonts.gstatic.com/s/didactgothic/v13/ahcfv8qz1zt6hCC5G4F_P4ASpUw.ttf)
      format("truetype");
}

$project-font-path: "../assets/fonts/";

@font-face {
  font-family: "Century Gothic";
  src: url("#{$project-font-path}GOTHIC.TTF");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Century Gothic";
  src: url("#{$project-font-path}GOTHICI.TTF");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Century Gothic";
  src: url("#{$project-font-path}GOTHICB.TTF");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Century Gothic";
  src: url("#{$project-font-path}GOTHICBI.TTF");
  font-weight: 600;
  font-style: italic;
}

$primary: #043d5d;
$secondary: #819eae;
$borderColor: #707071;
$site-gray: #f6f8fa;
$line-color: rgba(31, 35, 39, 0.08);

$success: #24c36d;
$danger: #ff4141;
$warning: #f4ea00;
$active: #007fff;

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  warning: $warning,
  danger: $danger,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1320px,
);

$font-family-base: "Century Gothic", "Didact Gothic", Helvetica, Arial,
  sans-serif;

// Toastify
$rt-color-default: #fff !default;
$rt-color-info: #3498db !default;
$rt-color-success: #07bc0c !default;
$rt-color-warning: #f1c40f !default;
$rt-color-error: #e74c3c !default;
