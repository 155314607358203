@import "styles/variables";

.goto-btn {
  border-color: #dee2e6;
  margin-left: 5px;
  background-color: white;
}

.se-pagination {
  .page-item {
    &.active {
      .page-link {
        span {
          &:first-child {
            color: white;
          }
        }
      }
    }
  }
}
