@import "styles/variables";

$success: #4eb54a;
$failed: #eb433d;
$border: #f1f1f1;

.modal-class {
  margin-top: 80px;
  border-radius: 5px;
  box-shadow: none;
  padding: 0px;

  .inner-record-goto {
    padding: 4rem 1rem;

    button {
      height: 50px;
      width: 153px;

      &:first-child {
        margin-right: 10px;
      }
    }

    h6 {
      margin: 0;
    }

    .form-group {
      margin: 0;

      .form-control {
        border: 1px solid $border;
        width: 120px;
        margin-left: 10px;
        border-radius: 5px;
      }
    }
  }
}
