@import "~styles/variables";
.programs-modal {
  .modal-content {
    padding: 35px 35px 35px 35px;
    border-radius: 0;
    .modal-header {
      padding: 0;
      border: none;
      margin-bottom: 8px;
      .modal-title {
        font-size: 24px;
        line-height: 29px;
      }
    }
    p {
      font-size: 14px;
      line-height: 17px;
    }
    .group {
      background-color: #f6f8fa;
      padding: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid #eaeaea;
      .group-title-collapsed {
        cursor: pointer;
        .group-title {
          h4 {
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
            margin: 14px 0;
          }
        }
        svg {
          width: 32px;
          transition: all 0.2s ease-in-out;
          path {
            fill: #6f6f72;
          }
        }
        &.isOpen {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
