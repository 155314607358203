@import "~styles/variables";
.date-picker {
  h4 {
    font-size: 18px;
    color: $borderColor;
    //margin-bottom: 24px;
    @media screen and (max-width: 1280px) {
      //margin-bottom: 0.6em;
    }
  }
  label {
    color: $borderColor;
  }
  .react-datepicker__month-select,
  .react-datepicker__year-select {
    border: 1px solid $borderColor;
    color: $borderColor;
    border-radius: 2px;
    font-size: 14px;
    box-sizing: border-box;
    transition: all 0.5s ease-in-out;
    height: 30px;
  }
  .react-datepicker-wrapper {
    margin-top: 5px;
  }
}
